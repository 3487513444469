<template>
  <div class="register-form">
    <v-dialog
      v-model="dialog"
      width="500"
      transition="dialog-bottom-transition"
      persistent
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="text-capitalize"
          color="light-green lighten-4"
          depressed
          v-bind="attrs"
          v-on="on"
        >
          tambah pengguna
          <v-icon right>mdi-account-plus</v-icon>
        </v-btn>
      </template>

      <v-card rounded="lg">
        <v-card-title class="light-green lighten-2">
          Tambah pengguna
          <v-spacer></v-spacer>
          <v-icon>mdi-badge-account-horizontal</v-icon>
        </v-card-title>
        <v-card-text class="my-5">
          <v-text-field
            label="Name"
            v-model="name"
            append-icon="mdi-account"
            :rules="emailRule"
          ></v-text-field>
          <v-text-field
            label="Email"
            v-model="email"
            append-icon="mdi-email"
            :rules="emailRule"
          ></v-text-field>
          <v-text-field
            label="Password"
            v-model="password"
            type="password"
            append-icon="mdi-lock"
            :rules="passwordRule"
          ></v-text-field>
          <!-- <v-text-field
            label="Confirm Password"
            v-model="confirmPassword"
            type="password"
            append-icon="mdi-lock-check"
            :rules="confirmRule"
          ></v-text-field> -->
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="py-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            Tutup <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn color="primary" text @click="addUser">
            Tambahkan Pengguna <v-icon right>mdi-account-plus</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      dialog: false,
      name: "",
      email: "",
      emailRule: [
          (value) => !!value || "Please type email"
      ],
      password: "",
      passwordRule: [
        (value) => !!value || "Please type password.",
        (value) => (value && value.length >= 6) || "minimum 6 characters",
      ],
      confirmPassword: "",
      confirmRule: [
        (value) => !!value || "type confirm password",
        (value) =>
          value === this.password ||
          "The password confirmation does not match.",
      ],
    };
  },
  methods: {
      async addUser(){
          await axios.post(`${process.env.VUE_APP_KB_API_URL}/api/register`, {
              name: this.name,
              email: this.email,
              password: this.password,
              //password_confirmation: this.comfirmPassword
          }, {
              headers: {
                  Authorization: `Bearer ${window.localStorage.getItem('token')}`
              }
          }).then(response => {
              console.log(response.data)
              console.log(response.status)
              location.reload()
          }).catch(err => {
              console.log(err)
          })
      }
  }
};
</script>