<template>
  <div class="users light-blue lighten-3 fill-height">
    <v-container>
      <v-card rounded="lg">
        <v-card-title>
          <v-btn
            depressed
            shaped
            color="indigo lighten-3"
            dark
            router
            to="/dashboard/main"
          >
            <v-icon>mdi-arrow-left-circle</v-icon>
          </v-btn>
          <v-divider vertical class="mx-5"></v-divider>
          Manage Users
          <v-spacer></v-spacer>
          <registerForm />
        </v-card-title>
      </v-card>
      <v-card class="mt-5 pa-5" rounded="lg">
        <v-card-subtitle>Users Table</v-card-subtitle>
        <v-data-table
          :headers="headers"
          @click:row="openDialog"
          :items="users"
        ></v-data-table>
      </v-card>
    </v-container>
    <v-dialog v-model="dialog" width="750">
      <v-card rounded="lg">
        <v-card-title class="deep-purple accent-1">
          <v-col>
            <h3 class="white--text">Edit User</h3>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-btn
              outlined
              color="white"
              @click="updateUser(update_value.id)"
              :loading="loading1"
              :disabled="loading1"
              class="mx-1"
            >
              <v-icon left>mdi-content-save</v-icon>
              Simpan
            </v-btn>
            <v-btn
              outlined
              color="white"
              @click="confirmation"
              :loading="loading2"
              :disabled="loading2"
              class="mx-1"
            >
              <v-icon left>mdi-delete</v-icon>
              Hapus
            </v-btn>
          </v-col>
        </v-card-title>
        <v-card-text>
          <v-card-subtitle>
            <h4 class="font-weight-light">User Info</h4>
          </v-card-subtitle>
          <v-col>
            <v-form ref="form" v-model="valid">
              <v-text-field
                label="Nama"
                :rules="nameRule"
                outlined
                rounded
                color="deep-purple accent-1"
                v-model="update_value.name"
              ></v-text-field>
              <v-text-field
                label="Email"
                :rules="emailRule"
                outlined
                rounded
                color="deep-purple accent-1"
                v-model="update_value.email"
              ></v-text-field>
              <v-text-field
                label="Password"
                type="password"
                v-model="update_value.password"
                :rules="passwordRule"
                outlined
                rounded
                color="deep-purple accent-1"
              ></v-text-field>
            </v-form>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar light v-model="snackbar" color="amber">
      <span>
        <v-icon left large>mdi-alert-circle-outline</v-icon>
        {{ snackbar_text }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
    <v-dialog width="700" v-model="confirm">
      <v-card>
        <v-card-title class="amber">
          <v-col>
            <v-row>
              <v-col class="d-flex justify-center">
                <v-icon color="black" x-large>mdi-alert-outline</v-icon>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <p class="font-weight-bold my-auto text-center">
                  Are You Sure Want To Delete This Data?
                </p>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-row>
              <v-col>
                <v-btn block depressed outlined @click="deleteUser(update_value.id)" color="white">Yes</v-btn>
              </v-col>
              <v-col>
                <v-btn
                  block
                  depressed
                  outlined
                  color="white"
                  @click="() => { confirm = !confirm; loading2 = false}"
                  >No</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-card-title>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import registerForm from "@/components/RegisterForm.vue";
import axios from "axios";
export default {
  data() {
    return {
      dialog: false,
      confirm: false,
      snackbar: false,
      snackbar_text: null,
      valid: true,
      loading1: false,
      loading2: false,
      nameRule: [(v) => !!v || "Name is required"],
      passwordRule: [(v) => !!v || "Password is required"],
      emailRule: [(v) => !!v || "Email is required"],
      headers: [
        { text: "Name", value: "name" },
        { text: "Email/Username/ID", value: "email" },
        { text: "Register At", value: "created_at" },
      ],
      users: [],
      update_value: {
        id: null,
        name: null,
        email: null,
        password: null,
      },
    };
  },
  methods: {
    async getUserData() {
      await axios
        .get(`${process.env.VUE_APP_KB_API_URL}/api/users`)
        .then((response) => {
          this.users = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openDialog(value) {
      this.dialog = true;
      console.log(value);
      this.update_value.id = value.id;
      this.update_value.name = value.name;
      this.update_value.email = value.email;
    },
    async updateUser(id) {
      this.loading = true;
      let vm = this;
      this.$refs.form.validate();
      console.log(id);
      if (this.valid === true) {
        await axios
          .put(
            `${process.env.VUE_APP_KB_API_URL}/api/user/${id}`,
            {
              name: this.update_value.name,
              email: this.update_value.email,
              password: this.update_value.password,
            },
            {
              headers: {
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
              },
            }
          )
          .then((response) => {
            console.log(response.data);
            vm.loading = false;
            location.reload();
            console.log("User data has been updated");
          })
          .catch((err) => {
            console.log(err);
            vm.loading = false;
            vm.snackbar = true;
            vm.snackbar_text = "Failed to save data";
            setTimeout(() => (vm.snackbar = false), 2000);
          });
        console.log("user input valid");
      } else {
        console.log("user input was not valid");
        this.loading = false;
        this.snackbar = true;
        this.snackbar_text = "User input was not valid";
        setTimeout(() => (this.snackbar = false), 2000);
      }
    },
    confirmation() {
      this.loading2 = true;
      this.confirm = true;
    },
    async deleteUser(id){
      console.log(id)
      let vm = this;
      await axios
        .delete(
          `${process.env.VUE_APP_KB_API_URL}/api/user/${id}`,
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          vm.confirm = false;
          vm.loading2 = false;
          location.reload();
        })
        .catch(function (error) {
          console.log(error);
          vm.loading2 = false;
          vm.snackbar = true
          vm.snackbar_text = "Failed to delete user"
        });
    }
  },
  components: {
    registerForm,
  },
  created() {
    this.getUserData();
  },
};
</script>